import React from 'react';
import { CrudEditModalProps } from '../crud/CrudTable';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { useUserService } from '../../services/UserService';
import z from 'zod';
import { UserSubscriptionCreateInput, UserSubscriptionListFragment } from '../../graphql/graphql';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import DateTimeUtils from '@dr-pam/common-components/Utils/DateTimeUtils';

const RequiredConfig = { required_error: 'Required', invalid_type_error: 'Required' };

const validationSchema = z
	.object({
		validFrom: z.date(RequiredConfig),
		validTo: z.null().or(z.date(RequiredConfig)),
	})
	.refine((data) => data.validTo === null || data.validFrom < data.validTo, {
		message: `"Valid Until" must be after "Valid From"`,
		path: ['validTo'],
	});

export type AddSubscriptionModalProps = CrudEditModalProps<UserSubscriptionListFragment, UserSubscriptionListFragment>;

export default function AddSubscriptionModal(props: AddSubscriptionModalProps) {
	const { modalId, onCancel, onEdited, current } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const userService = useUserService();

	const form = useForm<EditSubscriptionForm>({
		initialValues: {
			validFrom: typeof current.validFrom === 'string' ? dayjs(current.validFrom).toDate() : current.validFrom,

			validTo: typeof current.validTo === 'string' ? dayjs(current.validTo).toDate() : current.validTo,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: EditSubscriptionForm) => {
		const loader = addLoader();
		try {
			const updatedSubscription = await userService.updateSubscription(current.id, {
				validFrom: { set: values.validFrom },
				validTo: { set: values.validTo },
			});
			onEdited(updatedSubscription);
			NotificationUtils.showSuccess('Successfully updated subscription', 'Success');
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to update subscription');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						value={current.productSubscriptionType.product?.name}
						label="Product"
						description="The product to subscribe to"
						disabled
						withAsterisk
					/>
					<TextInput
						value={current.productSubscriptionType.name}
						label="Subscription type"
						description="The type of the subscription"
						disabled
						withAsterisk
					/>
					<DateInput
						{...form.getInputProps('validFrom')}
						dateParser={DateTimeUtils.parse}
						label="Valid From"
						description="(DD-MM-YYYY)"
						valueFormat="DD-MM-YYYY"
						popoverProps={{ withinPortal: true }}
						disabled={isLoading}
						withAsterisk
						clearable
					/>
					{current.productSubscriptionType.durationInDays ? (
						<DateInput
							{...form.getInputProps('validTo')}
							dateParser={DateTimeUtils.parse}
							label="Valid Until"
							description="(DD-MM-YYYY)"
							valueFormat="DD-MM-YYYY"
							popoverProps={{ withinPortal: true }}
							disabled={isLoading}
							withAsterisk
							clearable
						/>
					) : null}
					<Group justify="flex-end" mt="md">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Save subscription {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type EditSubscriptionForm = Pick<UserSubscriptionCreateInput, 'validFrom' | 'validTo'>;

import React, { useCallback, useState } from 'react';
import { usePaymentService } from '../../services/PaymentService';
import { Menu, ActionIcon, Tooltip, Text, Badge, Group } from '@mantine/core';
import { IconBrandStripe, IconDotsVertical, IconMail, IconUserDollar } from '@tabler/icons-react';
import { Link, useSearchParams } from 'react-router-dom';
import { PaymentListFragment } from '../../graphql/graphql';
import CurrencyUtils from '@dr-pam/common-components/Utils/CurrencyUtils';
import CrudTable, { useCrudState, CrudTableColumn } from '../crud/CrudTable';
import DateTimeUtils, { DATE_FORMAT, DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import { openConfirmModal } from '../modals/ConfirmModal';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import usePaginationState from '../../hooks/usePaginationState';
import { modals } from '@mantine/modals';
import IssueRefundModal from './IssueRefundModal';

export type PaymentsListProps = {
	className?: string;
};

export default function PaymentsList(props: PaymentsListProps) {
	const { className } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');

	const paymentService = usePaymentService();
	const pagination = usePaginationState();
	const { currentPage, setTotalPages } = pagination;

	const fetchPayments = useCallback(() => {
		if (searchQuery) {
			return paymentService.search(searchQuery);
		} else {
			const request = paymentService.getAllPaginated(currentPage);
			return {
				abort: request.abort,
				response: request.response.then((response) => {
					setTotalPages(Math.ceil(response.total / response.perPage));
					return response.items;
				}),
			};
		}
	}, [currentPage, paymentService, searchQuery, setTotalPages]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const crudState = useCrudState<PaymentListFragment, PaymentListFragment>(fetchPayments);

	const handleViewInStripeClicked = (payment: PaymentListFragment) => {
		window.open(
			`https://dashboard.stripe.com/payments/${payment.stripePaymentIntents[0].paymentIntentId}`,
			'_blank',
		);
	};

	const handleIssueRefundClicked = (payment: PaymentListFragment) => {
		const modalId = 'issue-refund';
		modals.open({
			modalId,
			closeOnClickOutside: false,
			closeOnEscape: false,
			withCloseButton: false,
			title: 'Issue refund',
			children: <IssueRefundModal modalId={modalId} payment={payment} onRefundComplete={crudState.refetch} />,
			trapFocus: true,
			onClose: () => modals.close(modalId),
		});
	};

	const handleResendInvoiceClicked = (payment: PaymentListFragment) => {
		openConfirmModal({
			title: 'Resend Invoice',
			children: <>Are you sure you want to resend this invoice to {payment.user.email}</>,
			confirmText: 'Resend',
			onConfirm: async () => {
				try {
					const request = paymentService.resendInvoice(payment.id);
					await request.response;
					NotificationUtils.showSuccess(`Sent to ${payment.user.email}`, 'Invoice resent');
				} catch (err) {
					NotificationUtils.showError(err as Error, 'Failed to resend invoice');
				}
			},
		});
	};

	const crudColumns = (payment: PaymentListFragment): CrudTableColumn[] => {
		const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
		const productSubscriptionType =
			payment.paymentItems.length > 0 ? payment.paymentItems[0].productSubscriptionType : null;
		const paymentIntent = payment.stripePaymentIntents[0];
		const discountInCents = payment.couponDiscountAppliedInCents;
		const chargedInCents = paymentIntent?.chargedInCents;
		const taxPaidInCents = paymentIntent?.taxPaidInCents;
		const feesPaidInCents = paymentIntent?.feesPaidInCents;
		const netInCents = paymentIntent?.netInCents;
		const refundedAmount = payment.refunds.reduce((acc, refund) => acc + refund.amountInCents, 0);
		const nonrefundedAmount = payment.amountInCents - refundedAmount;
		return [
			{
				children: (
					<Tooltip label={DateTimeUtils.format(payment.created, DATE_TIME_FORMAT)}>
						<Text size="sm">{DateTimeUtils.format(payment.created, DATE_FORMAT)}</Text>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={payment.user?.email}>
						<Text size="sm">{payment.user?.displayName}</Text>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={productSubscriptionType?.product?.name}>
						<Text size="sm">{productSubscriptionType?.product?.shortName}</Text>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={regionNames.of(payment.billingCountryCode)}>
						<Text size="sm">{payment.billingCountryCode}</Text>
					</Tooltip>
				),
			},
			{
				children:
					netInCents && feesPaidInCents && taxPaidInCents && chargedInCents ? (
						<Tooltip
							label={
								<Text>
									<strong>Charged:</strong> {CurrencyUtils.format(chargedInCents)} {payment.currency}
									<br />
									<strong>Discount:</strong> {CurrencyUtils.format(discountInCents ?? 0)}
									<br />
									<strong>Stripe fees:</strong> {CurrencyUtils.format(feesPaidInCents)}
									<br />
									<strong>GST:</strong> {CurrencyUtils.format(taxPaidInCents)}
									<br />
									<strong>Net:</strong> {CurrencyUtils.format(netInCents)}
								</Text>
							}
						>
							<span>{CurrencyUtils.format(chargedInCents)}</span>
						</Tooltip>
					) : (
						''
					),
			},
			{
				children: payment.xeroInvoiceNumber ? (
					<Text size="sm" ta="center">
						<a
							href={`/api/xero/invoice/${payment.xeroInvoiceNumber}/redirect`}
							target="_blank"
							rel="noreferrer"
						>
							{payment.xeroInvoiceNumber}
						</a>
					</Text>
				) : (
					<Text size="xs" ta="center">
						Pending
					</Text>
				),
			},
			{
				children: (
					<Group wrap="wrap">
						{refundedAmount > 0 && nonrefundedAmount > 0 ? (
							<Link to={`/finances/refunds?search=${payment.id}`}>
								<Badge color="yellow" style={{ cursor: 'pointer' }}>
									Partially refunded
								</Badge>
							</Link>
						) : refundedAmount > 0 ? (
							<Link to={`/finances/refunds?search=${payment.id}`}>
								<Badge color="pink" style={{ cursor: 'pointer' }}>
									Fully refunded
								</Badge>
							</Link>
						) : (
							<Badge color="green">Paid</Badge>
						)}
						{payment.isInvoiceSent ? (
							<Link to={`/api/payment/${payment.id}/invoice`} target="_blank" rel="noreferrer">
								<Badge color="blue" style={{ cursor: 'pointer' }}>
									Invoiced
								</Badge>
							</Link>
						) : (
							<Badge color="red">Not invoiced</Badge>
						)}
						{payment.stripePayoutId && <Badge color="grape">Paid out</Badge>}
					</Group>
				),
			},
			{
				children: (
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								leftSection={<IconUserDollar />}
								onClick={() => handleIssueRefundClicked(payment)}
								disabled={nonrefundedAmount === 0}
							>
								Issue Refund
							</Menu.Item>
							<Menu.Item leftSection={<IconMail />} onClick={() => handleResendInvoiceClicked(payment)}>
								Resend Invoice
							</Menu.Item>
							<Menu.Item
								leftSection={<IconBrandStripe />}
								onClick={() => handleViewInStripeClicked(payment)}
							>
								View in Stripe
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				),
			},
		];
	};

	return (
		<CrudTable
			className={className}
			items={crudState.items}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="payment"
			search={{
				onSearchRequested: handleSearchRequested,
				onSearchCleared: handleSearchRequested,
				initialValue: searchQuery,
			}}
			isLoading={crudState.isFetching}
			pagination={pagination}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Date',
	},
	{
		children: 'User',
	},
	{
		children: 'Product',
	},
	{
		children: 'Country',
	},
	{
		children: 'Amount',
	},
	{
		children: (
			<Text size="sm" ta="center" fw="bold">
				Xero Invoice
			</Text>
		),
	},
	{
		children: 'Status',
	},
	{
		children: '',
	},
];

import { ApolloClient, gql, useApolloClient } from '@apollo/client';
import useAbortRegistry from '@dr-pam/common-components/Hooks/useAbortRegistry';
import ApolloUtils from '@dr-pam/common-components/Utils/ApolloUtils';
import { useMemo } from 'react';
import {
	RefundListFragment,
	RefundsPaginatedQuery,
	RefundsQuery,
	SearchRefundsQuery,
	SearchRefundsQueryVariables,
} from '../graphql/graphql';
import FetchUtils from '@dr-pam/common-components/Utils/FetchUtils';
import { RegisterAbortFunction } from '@dr-pam/common-components/Utils/AbortUtils';
import { PaginatedResponse } from '@dr-pam/common-components/Utils/PaginationUtils';

export const GQL_FRAG_REFUND_LIST = gql`
	fragment RefundList on Refund {
		id
		created
		modified
		currency
		amountInCents
		xeroCreditNoteNumber
		stripePayoutId
		paymentId
		payment {
			created
			amountInCents
			paymentItems {
				productSubscriptionType {
					id
					name
					product {
						id
						name
						shortName
					}
				}
			}
			stripePaymentIntents(where: { status: { equals: SUCCEEDED } }) {
				paymentIntentId
			}
		}
		user {
			id
			email
			displayName
		}
	}
`;
export const GQL_GET_REFUNDS_FOR_LIST = gql`
	${GQL_FRAG_REFUND_LIST}
	query Refunds {
		refunds(orderBy: { created: desc }) {
			...RefundList
		}
	}
`;

export const GQL_GET_REFUNDS_FOR_LIST_PAGINATED = gql`
	${GQL_FRAG_REFUND_LIST}
	query RefundsPaginated($skip: Int, $take: Int) {
		refunds(orderBy: { created: desc }, skip: $skip, take: $take) {
			...RefundList
		}
		aggregateRefund {
			_count {
				_all
			}
		}
	}
`;

export const GQL_SEARCH_REFUNDS_FOR_LIST = gql`
	${GQL_FRAG_REFUND_LIST}
	query SearchRefunds($query: String!, $excludeIds: [String!]) {
		refunds(
			orderBy: { created: desc }
			where: {
				id: { notIn: $excludeIds }
				OR: [
					{ xeroCreditNoteNumber: { contains: $query } }
					{ payment: { is: { id: { equals: $query } } } }
					{
						user: {
							is: {
								OR: [
									{ email: { contains: $query, mode: insensitive } }
									{ displayName: { contains: $query, mode: insensitive } }
								]
							}
						}
					}
					{
						payment: {
							is: {
								paymentItems: {
									some: {
										productSubscriptionType: {
											is: {
												product: {
													is: {
														OR: [
															{ name: { contains: $query, mode: insensitive } }
															{ shortName: { contains: $query, mode: insensitive } }
														]
													}
												}
											}
										}
									}
								}
							}
						}
					}
				]
			}
		) {
			...RefundList
		}
	}
`;

export default class RefundService {
	constructor(
		private readonly _apolloClient: ApolloClient<unknown>,
		private readonly _registerAbort?: RegisterAbortFunction,
	) {}

	public getAll() {
		const request = ApolloUtils.abortableQuery<RefundsQuery, RefundListFragment[]>(
			this._apolloClient,
			{
				query: GQL_GET_REFUNDS_FOR_LIST,
			},
			(data) => data.refunds,
			this._registerAbort,
		);

		return request;
	}

	public getAllPaginated(page = 1, perPage = 20) {
		const request = ApolloUtils.abortableQuery<RefundsPaginatedQuery, PaginatedResponse<RefundListFragment>>(
			this._apolloClient,
			{
				query: GQL_GET_REFUNDS_FOR_LIST_PAGINATED,
				variables: {
					skip: (page - 1) * perPage,
					take: perPage,
				},
			},
			(data) => ({
				items: data.refunds,
				total: data.aggregateRefund._count?._all ?? 0,
				page,
				perPage,
			}),
			this._registerAbort,
		);

		return request;
	}

	public search(query: string, excludeIds?: string[]) {
		const request = ApolloUtils.abortableQuery<
			SearchRefundsQuery,
			RefundListFragment[],
			SearchRefundsQueryVariables
		>(
			this._apolloClient,
			{
				query: GQL_SEARCH_REFUNDS_FOR_LIST,
				variables: {
					query,
					excludeIds: excludeIds ?? [],
				},
			},
			(data) => data.refunds,
			this._registerAbort,
		);

		return request;
	}

	public issueRefund(paymentId: string, amountInCents: number) {
		const request = FetchUtils.postJson<never>(`/api/payment/${paymentId}/refund`, {
			amountInCents,
		});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function useRefundService() {
	const apolloClient = useApolloClient();
	const registerAbort = useAbortRegistry();

	return useMemo(() => new RefundService(apolloClient, registerAbort), [apolloClient, registerAbort]);
}

import React, { useCallback, useState } from 'react';
import { Menu, ActionIcon, Tooltip, Text, Group, Badge } from '@mantine/core';
import { IconBrandStripe, IconDotsVertical } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { RefundListFragment } from '../../graphql/graphql';
import CurrencyUtils from '@dr-pam/common-components/Utils/CurrencyUtils';
import CrudTable, { useCrudState, CrudTableColumn } from '../crud/CrudTable';
import DateTimeUtils, { DATE_FORMAT, DATE_TIME_FORMAT } from '@dr-pam/common-components/Utils/DateTimeUtils';
import usePaginationState from '../../hooks/usePaginationState';
import { useRefundService } from '../../services/RefundService';
import { Link } from 'react-router-dom';

export type RefundsListProps = {
	className?: string;
};

export default function RefundsList(props: RefundsListProps) {
	const { className } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchQuery, setSearchQuery] = useState(searchParams.get('search') ?? '');
	const refundService = useRefundService();
	const pagination = usePaginationState();
	const { currentPage, setTotalPages } = pagination;

	const fetchRefunds = useCallback(() => {
		if (searchQuery) {
			return refundService.search(searchQuery);
		} else {
			const request = refundService.getAllPaginated(currentPage);
			return {
				abort: request.abort,
				response: request.response.then((response) => {
					setTotalPages(Math.ceil(response.total / response.perPage));
					return response.items;
				}),
			};
		}
	}, [currentPage, refundService, searchQuery, setTotalPages]);

	const handleSearchRequested = useCallback(
		(query?: string) => {
			if (query) {
				setSearchParams({ search: query }, { replace: true });
				setSearchQuery(query);
			} else {
				setSearchParams({}, { replace: true });
				setSearchQuery('');
			}
		},
		[setSearchParams],
	);

	const handleViewInStripeClicked = (refund: RefundListFragment) => {
		window.open(
			`https://dashboard.stripe.com/payments/${refund.payment.stripePaymentIntents[0].paymentIntentId}`,
			'_blank',
		);
	};

	const crudState = useCrudState<RefundListFragment, RefundListFragment>(fetchRefunds);

	const crudColumns = (refund: RefundListFragment): CrudTableColumn[] => {
		const productSubscriptionType =
			refund.payment.paymentItems.length > 0 ? refund.payment.paymentItems[0].productSubscriptionType : null;

		const nonrefundedAmount = refund.payment.amountInCents - refund.amountInCents;

		return [
			{
				children: (
					<Tooltip label={DateTimeUtils.format(refund.created, DATE_TIME_FORMAT)}>
						<span>{DateTimeUtils.format(refund.created, DATE_FORMAT)}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={refund.user?.email}>
						<span>{refund.user?.displayName}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip label={productSubscriptionType?.product?.name}>
						<span>{productSubscriptionType?.product?.shortName}</span>
					</Tooltip>
				),
			},
			{
				children: (
					<Tooltip
						label={
							<Text>
								{CurrencyUtils.format(refund.amountInCents)} {refund.currency}
							</Text>
						}
					>
						<span>{CurrencyUtils.format(refund.amountInCents)}</span>
					</Tooltip>
				),
			},
			{
				children: refund.xeroCreditNoteNumber ? (
					<Text size="sm" ta="center">
						<a
							href={`/api/xero/creditNote/${refund.xeroCreditNoteNumber}/redirect`}
							target="_blank"
							rel="noreferrer"
						>
							{refund.xeroCreditNoteNumber}
						</a>
					</Text>
				) : (
					<Text size="xs" ta="center">
						Pending
					</Text>
				),
			},
			{
				children: (
					<Group wrap="wrap">
						{refund.amountInCents > 0 && nonrefundedAmount > 0 ? (
							<Link to={`/finances/payments?search=${refund.paymentId}`}>
								<Badge color="yellow" style={{ cursor: 'pointer' }}>
									Partially refunded
								</Badge>
							</Link>
						) : refund.amountInCents > 0 ? (
							<Link to={`/finances/payments?search=${refund.paymentId}`}>
								<Badge color="pink" style={{ cursor: 'pointer' }}>
									Fully refunded
								</Badge>
							</Link>
						) : null}
						{refund.stripePayoutId && <Badge color="grape">Paid out</Badge>}
					</Group>
				),
			},
			{
				children: (
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<ActionIcon color="dark" variant="subtle">
								<IconDotsVertical />
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item
								leftSection={<IconBrandStripe />}
								onClick={() => handleViewInStripeClicked(refund)}
							>
								View in Stripe
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				),
			},
		];
	};

	return (
		<CrudTable
			className={className}
			items={crudState.items}
			headers={crudHeaders}
			columns={crudColumns}
			entityName="refund"
			search={{
				onSearchRequested: handleSearchRequested,
				onSearchCleared: handleSearchRequested,
				initialValue: searchQuery,
			}}
			isLoading={crudState.isFetching}
			pagination={pagination}
		/>
	);
}

const crudHeaders: CrudTableColumn[] = [
	{
		children: 'Date',
	},
	{
		children: 'User',
	},
	{
		children: 'Product',
	},
	{
		children: 'Refund Amount',
	},
	{
		children: (
			<Text size="sm" ta="center" fw="bold">
				Xero Credit Note
			</Text>
		),
	},
	{
		children: 'Status',
	},
	{
		children: '',
	},
];

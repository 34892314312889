import React from 'react';
import { CrudAddModalProps } from '../crud/CrudTable';
import useComputedSlug from '../../hooks/useComputedSlug';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { Button, Container, Group, Loader, Select, Stack, Switch, TextInput, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useProductService } from '../../services/ProductService';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { BrandingType, ProductListFragment, ProductSingleFragment } from '../../graphql/graphql';
import z from 'zod';

export const validationSchema = z.object({
	name: z.string().nonempty().max(512),
	shortName: z.string().nonempty().max(512),
	slug: z.string().nonempty().max(512),
	brandingType: z.nativeEnum(BrandingType),
	description: z.string().nonempty(),
});

export type AddProductModalProps = CrudAddModalProps<ProductListFragment, ProductSingleFragment>;

export default function AddProductModal(props: AddProductModalProps) {
	const { modalId, onCancel, onCreated } = props;

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const productService = useProductService();

	const form = useForm<AddProductForm>({
		initialValues: {
			name: '',
			shortName: '',
			slug: '',
			brandingType: BrandingType.DrPam,
			description: '',
			healthProfessionalOnly: false,
		},
		validate: zodResolver(validationSchema),
	});

	const handleSubmit = async (values: AddProductForm) => {
		const loader = addLoader();

		try {
			const createdProduct = await productService.create(values);
			onCreated(createdProduct);
			NotificationUtils.showSuccess('Successfully created product', createdProduct.name);
			modals.close(modalId);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Failed to create product');
			removeLoader(loader);
		}
	};

	const handleCancel = () => {
		onCancel?.();
		modals.close(modalId);
	};

	const slugComputedFromProps = useComputedSlug(form, 'slug', 'name');

	return (
		<Container>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<TextInput
						{...slugComputedFromProps}
						label="Name"
						description="The name of the product. Forms the basis of the Slug."
						disabled={isLoading}
						withAsterisk
					/>
					<TextInput
						{...form.getInputProps('shortName')}
						label="Short name"
						description="The name to display when space is limited."
						disabled={isLoading}
						withAsterisk
					/>
					<Textarea
						{...form.getInputProps('description')}
						label="Description"
						description="The description of the product."
						disabled={isLoading}
						withAsterisk
						rows={5}
					/>
					<TextInput
						{...form.getInputProps('slug')}
						label="Slug"
						description="This will appear in the browser URL."
						disabled={isLoading}
						withAsterisk
					/>
					<Select
						{...form.getInputProps('brandingType')}
						label="Branding"
						description="The branding used for this product."
						disabled={isLoading}
						withAsterisk
						data={Object.values(BrandingType)}
					/>
					<Switch
						{...form.getInputProps('healthProfessionalOnly')}
						label="Health professional only"
						description="Require health professional registration details during sign-up"
						disabled={isLoading}
					/>
					<Group justify="flex-end">
						<Button type="button" variant="subtle" onClick={handleCancel} disabled={isLoading}>
							Cancel
						</Button>
						<Button type="submit" disabled={isLoading}>
							Add product {isLoading && <Loader size="xs" ml="xs" />}
						</Button>
					</Group>
				</Stack>
			</form>
		</Container>
	);
}

export type AddProductForm = Pick<
	ProductSingleFragment,
	'name' | 'shortName' | 'slug' | 'brandingType' | 'description' | 'healthProfessionalOnly'
>;
